import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useScrollTo } from '../../hooks/use-scroll-to';
import Button from '../atoms/Button';
import SimpleHeroSection from '../molecules/SimpleHeroSection';

interface LocationsHeroSectionProps {
  title: string;
  body: string;
}

const LocationsHeroSection = ({ title, body }: LocationsHeroSectionProps) => {
  const { scrollTo } = useScrollTo();
  const {
    allWpCity: { nodes: cities },
  } = useStaticQuery(graphql`
    {
      allWpCity(sort: { fields: title }) {
        nodes {
          title
          slug
        }
      }
    }
  `);
  return (
    <SimpleHeroSection>
      <div className="container text-center">
        <h1 className="mb-4 u-h2">{title}</h1>
        <p className="max-w-2xl mx-auto mb-10 u-p">{body}</p>

        <div className="flex flex-wrap justify-center md:space-x-8">
          {cities.map(({ title, slug }) => (
            <div key={slug} className="py-2 mr-4 md:mr-0">
              <Button onClick={() => scrollTo(slug)} variant="pink">
                {title}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </SimpleHeroSection>
  );
};

export default LocationsHeroSection;
