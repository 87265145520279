import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import WaveLinen from '../../images/wave-linen.png';

interface SimpleHeroSectionProps {
  paddingTight?: boolean;
}

const SimpleHeroSection = ({
  paddingTight,
  children,
}: PropsWithChildren<SimpleHeroSectionProps>) => {
  const { scrollY } = useViewportScroll();
  const x = useTransform(scrollY, [0, 5000], [0, -1000]);
  const {
    wp: {
      bannerSettings: {
        bannerSettings: { visible: bannerVisible },
      },
    },
  } = useStaticQuery(graphql`
    query BannerSettings {
      wp {
        bannerSettings {
          bannerSettings {
            visible
          }
        }
      }
    }
  `);

  const paddingTopNormal = paddingTight ? 'pt-32' : 'pt-48';
  const paddingTopBanner = bannerVisible ? 'pt-52' : 'pt-64';

  return (
    <>
      <section
        className={`${bannerVisible ? paddingTopBanner : paddingTopNormal} ${
          paddingTight ? 'pb-16' : 'pb-24'
        } bg-linen`}
      >
        {children}
      </section>
      <div className="relative h-8">
        <div className="absolute top-0 left-0 right-0 h-8 overflow-hidden">
          <motion.div
            className="w-[10000px] h-full bg-repeat-x"
            style={{
              backgroundImage: `url(${WaveLinen})`,
              backgroundSize: '1.5% 100%',
              x: x,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SimpleHeroSection;
