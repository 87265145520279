import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import FeatureSection from '../components/organisms/FeatureSection';
import LocationRegionSection from '../components/organisms/LocationRegionSection';
import LocationsHeroSection from '../components/organisms/LocationsHeroSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const Locations = ({
  data: {
    page,
    allWpCity: { nodes: cities },
    allWpLocation: { nodes: locations },
    places: { nodes: places },
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <LocationsHeroSection {...page.locations.locationsHero} />
        {cities.map((city, index) => (
          <LocationRegionSection
            key={city.slug}
            index={index}
            {...city}
            locations={locations.filter(
              ({ location }) =>
                location.city?.id === city.id && !location.hideInLocationList
            )}
            variant={index % 2 === 0 ? 'white' : 'linen'}
            places={places}
            noWave={cities.length % 2 === 0 && index === cities.length - 1}
          />
        ))}

        {cities.length % 2 === 0 && <div className="h-14" />}

        <FeatureSection inverted {...page.locations.featuresection} />
        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query LocationsPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      locations {
        locationsHero {
          title
          body
        }
        featuresection {
          heading
          body
          image {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1472, placeholder: BLURRED)
              }
            }
            altText
          }
          buttonLabel
          buttonUrl
        }
      }
    }
    allWpCity(sort: { fields: title }) {
      nodes {
        id
        title
        slug
      }
    }
    allWpLocation(sort: { fields: title }) {
      nodes {
        title
        slug
        location {
          city {
            ... on WpCity {
              id
            }
          }
          googlePlaceId
          timezone
          address
          comingSoon
          comingSoonDate
          illustration
          photo {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1472, placeholder: BLURRED)
              }
            }
            altText
          }
          hideInLocationList
        }
      }
    }
    places: allGooglePlacesPlace {
      nodes {
        name
        place_id
        opening_hours {
          periods {
            open {
              day
              time
            }
            close {
              day
              time
            }
          }
        }
        formatted_address
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Locations;
