import { motion, useTransform, useViewportScroll } from 'framer-motion';
import orderBy from 'lodash/orderBy';
import React, { PropsWithChildren } from 'react';
import LocallySourcedImage from '../../images/locally-sourced.png';
import WaveLinen from '../../images/wave-linen.png';
import LocationsDonut1 from '../atoms/LocationsDonut1';
import LocationsDonut2 from '../atoms/LocationsDonut2';
import LocationCard from '../molecules/LocationCard';

interface LocationRegionSectionProps {
  index: number;
  title: string;
  slug: string;
  locations: Array<{
    title: string;
    slug: string;
    location: {
      googlePlaceId: string;
      illustration?: string;
      timezone: string;
      address: string;
      comingSoon: boolean;
      comingSoonDate?: string;
      photo: {
        localFile: {
          url: string;
        };
        altText: string;
      };
    };
  }>;
  variant: 'white' | 'linen';
  places: any[];
  noWave?: boolean;
}

const LocationRegionSection = ({
  index,
  title,
  slug,
  locations,
  variant,
  places,
  noWave,
}: PropsWithChildren<LocationRegionSectionProps>) => {
  const { scrollY } = useViewportScroll();
  const x = useTransform(scrollY, [0, 7000], [0, -1000]);
  const y1 = useTransform(scrollY, [0, 2000], [150, -300]);
  const y2 = useTransform(scrollY, [0, 2000], [750, 300]);
  const y3 = useTransform(scrollY, [0, 4000], [1250, 400]);

  if (locations.length === 0) {
    return null;
  }

  return (
    <>
      <section
        className={`relative py-10 md:py-24 ${
          variant === 'linen' && 'bg-linen'
        } overflow-hidden`}
        id={slug}
      >
        {index === 1 && (
          <>
            <LocationsDonut1
              className="absolute right-0 w-36 lg:w-72"
              style={{ y: y1 }}
            />
            <LocationsDonut2
              className="absolute left-0 hidden lg:block"
              style={{ y: y2 }}
            />
          </>
        )}
        {index === 3 && (
          <>
            <LocationsDonut1
              className="absolute right-0 z-0 hidden w-40 h-auto lg:block"
              style={{ y: y3 }}
            />
            <motion.img
              src={LocallySourcedImage}
              alt="Locally Sourced"
              className="absolute hidden h-auto select-none w-28 right-32 top-32 lg:block"
              style={{ y: y3 }}
            />
          </>
        )}
        <div className="container">
          <h2 className="mb-12 text-center u-h3">{title}</h2>

          <div className="flex flex-col flex-wrap justify-center space-y-8 lg:flex-row lg:space-y-0 lg:space-x-8">
            {orderBy(locations, 'location.comingSoon', 'desc').map(
              (location) => (
                <LocationCard
                  key={location.title}
                  name={location.title}
                  slug={location.slug}
                  illustration={location.location.illustration}
                  photo={location.location.photo}
                  address={location.location.address}
                  comingSoon={location.location.comingSoon}
                  comingSoonDate={location.location.comingSoonDate}
                  timezone={location.location.timezone}
                  place={places.find(
                    (p) => p.place_id === location.location.googlePlaceId
                  )}
                />
              )
            )}
          </div>
        </div>
      </section>
      {variant === 'linen' && !noWave && (
        <div className="relative h-8">
          <div className="absolute top-0 left-0 right-0 h-8 overflow-hidden">
            <motion.div
              className="w-[10000px] h-full bg-repeat-x"
              style={{
                backgroundImage: `url(${WaveLinen})`,
                backgroundSize: '1.5% 100%',
                x: x,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LocationRegionSection;
