import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const LocationsDonut1 = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="279"
    height="328"
    fill="none"
    viewBox="0 0 279 328"
    {...props}
  >
    <path
      fill="#F4EBDF"
      d="M181.639 143.962a31.365 31.365 0 016.468 3.267c14.954 10.087 4.356 26.517-1.655 38.359-.798 1.59-3.245 5.538-3.863 6.738-1.174 1.177-2.521 2.419-3.436 3.31a33.714 33.714 0 01-2.852 1.781c-1.304-.246-.964-.107 0 0l-.406.225c-1.646.087-3.592.214-4.511.312-1.479-.246-4.373-.633-4.841-.743a12.265 12.265 0 00-.799-.452c-1.058-.583-2.141-1.16-3.204-1.768-.428-.469-2.754-2.339-3.164-2.731-1.628-1.571-3.266-3.125-4.806-4.782-.197-.279-.54-.793-1.165-1.661-1.716-2.384-3.284-4.827-4.836-7.315-.721-1.182-2.825-5.414-2.971-5.529a91.364 91.364 0 01-3.857-11.996c.012-.244.016-.527.013-.903-.008-1.651-.045-3.497-.192-5.171-.568-6.43 4.833-10.865 10.322-12.565a45.038 45.038 0 012.453-.684c-.762.076-1.648.141-2.784.167 1.018-.013 2.029-.119 3.052-.227 2.072-.465 2.506-.387 1.439-.175 1.815-.232 3.624-.49 5.423-.557a53.723 53.723 0 0120.172 3.1zm62.28-122.518C212.71 6.856 188.947-2.076 153.086 1.9c-39.455 4.366-68.143 23.331-96.11 49.593C25.254 81.31 1.24 117.423.776 157.612a186.113 186.113 0 0022.266 89.423c20.366 37.954 52.122 56.545 94.894 69.08 40.374 11.849 86.965 17.991 124.516-1.468 32.579-16.827 59.641-43.827 73.449-78.123 1.308-3.276 2.47-6.6 3.545-9.959a171.814 171.814 0 0014.448-108.655c-8.477-42.15-46.885-76.275-89.969-96.44"
    ></path>
  </motion.svg>
);

export default LocationsDonut1;
