import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const LocationsDonut2 = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="139"
    height="163"
    fill="none"
    viewBox="0 0 139 163"
    {...props}
  >
    <path
      fill="#DFC2C2"
      d="M61.197 71.459c1.13.415 2.21.96 3.215 1.624 7.433 5.014 2.165 13.18-.823 19.066-.397.79-1.613 2.753-1.92 3.35-.583.584-1.253 1.202-1.708 1.645-.468.325-.937.617-1.417.885-.649-.122-.48-.053 0 0l-.202.112c-.819.043-1.786.106-2.242.155-.736-.123-2.174-.315-2.407-.37a6.15 6.15 0 00-.397-.224c-.526-.29-1.064-.577-1.593-.879-.212-.233-1.369-1.163-1.573-1.357-.809-.78-1.623-1.554-2.388-2.377-.098-.139-.269-.394-.58-.826-.852-1.185-1.632-2.4-2.403-3.636-.359-.587-1.404-2.69-1.477-2.748a45.485 45.485 0 01-1.917-5.963 32.044 32.044 0 00-.089-3.02c-.282-3.195 2.402-5.4 5.13-6.245.494-.15.884-.258 1.22-.34-.379.039-.82.07-1.384.083a16.396 16.396 0 001.517-.112c1.03-.231 1.246-.193.715-.087.903-.115 1.802-.243 2.696-.277 3.41-.132 6.813.391 10.027 1.54zM92.153 10.56C76.64 3.31 64.83-1.13 47.004.846c-19.611 2.17-33.87 11.597-47.772 24.65-15.768 14.822-27.704 32.771-27.935 52.748a92.51 92.51 0 0011.067 44.448c10.123 18.865 25.908 28.106 47.168 34.337 20.068 5.889 43.227 8.942 61.892-.73 16.193-8.364 29.645-21.784 36.508-38.831a74.846 74.846 0 001.762-4.951 85.406 85.406 0 007.182-54.008c-4.214-20.95-23.305-37.913-44.72-47.936"
    ></path>
  </motion.svg>
);

export default LocationsDonut2;
